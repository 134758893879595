import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      headingColor: 'getThemeHeadingColor',
      overlayColor: 'getThemeAlertShadowOverlay',
    }),
    cssVars() {
      const themeColor = this.headingColor || '#333';
      const themeColorTwo = `${themeColor}05`;
      const themeColorFive = `${themeColor}13`;
      const themeColorTwenty = `${themeColor}33`;
      return {
        '--primary-forms-color': themeColor,
        '--light-forms-color': themeColorFive,
        '--medium-forms-color': themeColorTwenty,
        '--empty-forms-color': themeColorTwo,
        '--overlay-color': this.overlayColor,
      };
    },
  },
};
