<template>
  <div class="success-container">
    <svg
      width="118"
      height="118"
      viewBox="0 0 118 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="118"
        height="118"
        rx="59"
        fill="#3BB5B5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.5183 0.438294C38.1464 1.03606 38.1623 2.02086 37.5539 2.6379L13.0122 27.5268C12.7099 27.8334 12.2927 28.0044 11.8583 27.9999C11.4238 27.9954 11.0104 27.8157 10.7147 27.5029L0.423084 16.614C-0.171937 15.9845 -0.134833 15.0002 0.505959 14.4157C1.14675 13.8311 2.14857 13.8675 2.7436 14.4971L11.8993 24.1841L35.2794 0.473209C35.8879 -0.143838 36.8903 -0.15947 37.5183 0.438294Z"
        fill="white"
        class="checkmark"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: 'SuccessCheck'
};
</script>
<style scoped>
.success-container {
  text-align: center;
}
.checkmark {
  transform: translate(41px, 45px);
}
</style>
