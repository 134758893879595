<template>
  <div class="error-bang">
    <svg
      width="118"
      height="118"
      viewBox="0 0 118 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="118"
        height="118"
        rx="59"
        fill="#CC314B"
      />
      <path
        d="M0.269792 10.8667V0.333333H9.73646V10.8667L7.20313 34.0667H2.80313L0.269792 10.8667ZM0.136459 38.3333H9.86979V48H0.136459V38.3333Z"
        fill="white"
        class="point"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: 'ErrorBang'
};
</script>
<style scoped>
.error-bang {
  text-align: center;
}
.point {
  transform: translate(55px, 34px);
}
</style>
