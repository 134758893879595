<template>
  <div
    class="container"
    :style="cssVars"
  >
    <h1 class="message">{{ messageValue }}</h1>
    <FormButton
      v-if="!hideBtn"
      class="form-button"
      variant="primary"
      @click="$emit('close-unavailable')"
      @keypress.enter="$emit('close-unavailable')"
    >
      {{ $t('forms.goToFormsList') }}
    </FormButton>
  </div>
</template>

<script>
import FormButton from '../components/dscl/FormButton';
import cssVars from '../components/mixins/color-vars';

export default {
  name: 'UnavailableForm',
  props: {
    hideBtn: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
  },
  components: {
    FormButton,
  },
  computed: {
    messageValue() {
      return this.message || this.$t('forms.unavailableForm');
    },
  },
  mixins: [cssVars],
};
</script>

<style lang="scss" scoped>
.container {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .message {
    color: var(--primary-forms-color);
    margin-bottom: 64px;
    font-size: 36px;
    line-height: 48px;
  }
  .form-button {
    width: 138px;
    height: 36px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    margin: 0;
  }
}
</style>
